export default class CommentToggle {

  constructor() {

    const comments = document.querySelectorAll('#summary-table .comment');

    comments.forEach(c => {
      c.addEventListener('click', (e) => {
        e.target.classList.toggle('active');
      })
    })

  }

}


class SelectQuestion {

  constructor() {

    // For all Select-Questions
    let surveyGroups = $('.select-question');
    surveyGroups.each((k, s) => {
      this.init(s);
    });
    
  }

  init(s) {

    // Variables
    let group = $(s);
    let select = group.find('select.choice');
    let scoreInput = group.find('input.score-input');
    let scoreDisplay = group.find('.score-display');
    let answerInput = group.find('input.answer-input');

    // Set values on change
    select.on('change', (e) => {
      setValues($(e.currentTarget));
    })

    // Set values
    function setValues(s) {
      let choice = s.find(':selected').text();
      let points = select.find(':selected').attr('data-points');
      answerInput.val(choice);
      scoreDisplay.text(points);
      scoreInput.val(points);
      if(choice === "...") {
        scoreDisplay.text(0);
        scoreInput.val(0);
      }
    }

    // Set initially
    setValues(group.find('select.choice:selected'));

  }

}

export default SelectQuestion

class Table {

  constructor() {

	let that = this;

    $(document).ready(function () {
      const table = $('#applicants-list');
      if (table.length) {
        that.init(table);
      }
    });

  }

  init(table) {
    table.DataTable({
      paging: false,
      order: [
        [1, 'desc']
      ],
    });

	// Make table row clickable
	table.find('tbody tr').each((k, v) => {
		let row = $(v);
		row.click((c) => {
			let link = row.data('url');
			window.location.href = link;
		})
	});
		

  }

}

export default Table

class YesNoQuestion {

  constructor() {
    let surveyGroups = $('.yes-no-question');
    surveyGroups.each((k, s) => {
      this.init(s);
    });
  }

  init(s) {
    let group = $(s);
    let radioNo = group.find('input[value=no]');
    let radioYes = group.find('input[value=yes]');
    let answerInput = group.find('input.answer-input');
    let tagInput = group.find('input.tag-input');
    let scoreInput = group.find('.score-input');
    let scoreDisplay = group.find('.score-display');    
    let tagSet = false;
    let tagValue = tagInput.data('tag');

    if (tagInput.data('tag') != "") {
      tagSet = true;
    }

    if(group.find('.badge.active').length) {
      scoreDisplay.hide();
    }

    radioNo.change(function (e) {
      if (tagSet) {
        let tagClass = "." + tagInput.data('tag');
        let badge = group.find('.badge' + tagClass)
        badge.addClass('active');
        tagInput.val(tagValue);
      }
      scoreDisplay.hide();
      scoreInput.val(0);
      scoreDisplay.text(0);
      answerInput.val("no");
    });
    
    radioYes.change(function (e) {
      if (tagSet) {
        let tagClass = "." + tagInput.data('tag');
        let badge = group.find('.badge' + tagClass)
        badge.removeClass('active');
        tagInput.val("");
      }
      scoreDisplay.show();
      scoreInput.val(scoreDisplay.data('points'));
      scoreDisplay.text(scoreDisplay.data('points'));
      answerInput.val("yes");
    });
  }

}

export default YesNoQuestion

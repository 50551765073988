class Nav {

  constructor() {

    console.log("load navigation module");
    $('nav a.toggle').click((e) => {
      e.preventDefault();
      let submenu = $($(e.currentTarget).next('.submenu'));
      submenu.toggleClass('visible');
    });

  }

}

export default Nav

import Nav from './modules/nav';
import MobileNav from './modules/mobileNav';
import YesNoQuestion from './modules/yesNoQuestion';
import SelectQuestion from './modules/selectQuestion';
import Table from './modules/table';
import CommentToggle from './modules/commentToggle';

new Nav();
new MobileNav();
new YesNoQuestion();
new SelectQuestion();
new Table();
new CommentToggle();

$("[data-toggle=info]").popover();


Fancybox.bind(".gallery a", {
  groupAll: true, // Group all items
  on: {
    ready: (fancybox) => {
      console.log(`fancybox #${fancybox.id} is ready!`);
    }
  }
});
